import React, { useContext, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { MdArrowOutward } from "react-icons/md";
import DiningFeatures from "./DiningFeatures";
import OtherRestaurants from "./OtherRestaurants";
import bannerCover from "../../assets/defaultBannerAndIcon/banner-placehoder.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "react-query";
import PageNotFound from "../../components/PageNotFound";
import Helmet from "../../components/Helmet";
import {
  getAllRestaurants,
  getRestaurantDetails,
} from "../../services/dataServices";
// import { MyContext } from "../../Contex/LoadingContex";
// import Loader from "../../components/Loader";
import BannerImage from "../../components/BannerImage";
function DiningDetails({ setErrorPage, slugOfDining }) {
  const { restaurantId } = useParams();

  let restaurantDetails = useQuery(["restaurantDetails", restaurantId], () =>
    getRestaurantDetails(restaurantId)
  );
  const allRestaurants = useQuery("allRestaurants", getAllRestaurants);
  // const { setIsLoadingMain } = useContext(MyContext);
  const [isBannerLoading, setIsBannerLoading] = useState(true);
  if (restaurantDetails?.data?.data === 404) {
    return (
      <>
        <PageNotFound setErrorPage={setErrorPage} />
      </>
    );
  }

  const singleRestaurantDetails = restaurantDetails?.data?.data[0];

  const restRestaurants = allRestaurants?.data?.data.filter(
    (item) => +item.slug !== restaurantId
  );

  const keywords = singleRestaurantDetails?.keywords;
  const slug = singleRestaurantDetails?.slug;
  const meta_title = singleRestaurantDetails?.meta_title;
  const meta_description = singleRestaurantDetails?.meta_description;

  const otherRestaurantsSlides = {
    arrows: true,
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow:
      allRestaurants?.data?.data?.length - 1 < 3
        ? allRestaurants?.data?.data?.length - 1
        : 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          vertical: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  // const handleError = () => {
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };

  // const handleImageLoad = () => {
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };

  // if (restaurantDetails.isLoading) {
  //   setIsLoadingMain(true);
  //   return <Loader />;
  // } else if (isBannerLoading) {
  //   setIsLoadingMain(true);
  // }

  return (
    <>
      <Helmet
        keywords={keywords}
        slug={slug}
        metaDescription={meta_description}
        title={meta_title}
      />
      <section>
        <div className="inner-cover header-space position-relative">
          <div className="our-story position-absolute start-0 top-0 h-100 cover-overlay">
            {singleRestaurantDetails?.inner_banner_image ||
            singleRestaurantDetails?.inner_banner_image !== null ? (
              // <img
              //   className="w-100 h-100"
              //   src={singleRestaurantDetails?.inner_banner_image}
              //   title=""
              //   alt={singleRestaurantDetails?.inner_banner_alt_tag}
              //   onLoad={handleImageLoad}
              //   onError={handleError}
              // />
              <BannerImage
                className="w-100 h-100"
                src={singleRestaurantDetails?.inner_banner_image}
                title=""
                alt={singleRestaurantDetails?.inner_banner_alt_tag}
                isDataIsLoading={restaurantDetails.isLoading}
              />
            ) : (
              // <img
              //   className="w-100 h-100"
              //   src={bannerCover}
              //   title=""
              //   alt={singleRestaurantDetails?.inner_banner_alt_tag}
              // />
              <BannerImage
                className="w-100 h-100"
                src={bannerCover}
                title=""
                alt={singleRestaurantDetails?.inner_banner_alt_tag}
                isDataIsLoading={restaurantDetails.isLoading}
              />
            )}
          </div>
          <div className="page-inner position-relative text-center">
            <div className="page-title d-flex justify-content-center">
              <h2 className="text-uppercase text-white georgia py-xl-4 py-3 px-5">
                {singleRestaurantDetails?.restaurant_name}
              </h2>
            </div>
            <Breadcrumb className="d-flex justify-content-center mt-4 text-white">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/${slugOfDining}`}>Dining</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {singleRestaurantDetails?.restaurant_name}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </section>

      <section>
        <div className="dining-head-info section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="dining-left-side mb-lg-0 mb-4">
                  <div className="dining-name-time">
                    <div className="bar-menu-time">
                      <h3 className="text-uppercase georgia letter-spacing-2 mb-lg-3">
                        {singleRestaurantDetails?.restaurant_name}
                      </h3>
                      {singleRestaurantDetails?.tags ? (
                        <div className="dining-timing">
                          {singleRestaurantDetails?.tags
                            .filter(
                              (item) =>
                                item.restaurant_tag !== null &&
                                item.restaurant_tag.trim() !== ""
                            )
                            .map((time) => (
                              <span key={time.id}>{time.restaurant_tag}</span>
                            ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {singleRestaurantDetails?.restaurant_menu_image &&
                  singleRestaurantDetails?.restaurant_menu_image !== "null" ? (
                    <div className="show-menu mt-lg-5 pt-xl-4">
                      <Link
                        target="_blank"
                        to={singleRestaurantDetails?.restaurant_menu_image}
                        className="georgia btn theme-btn-outline btn-outline-success fw-medium text-text-capitalize border-theme"
                      >
                        Show Menu <MdArrowOutward className="ms-2 fs-4" />
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* /col-lg-6 */}
              <div className="col-lg-6 mt-lg-0 mt-3">
                <span className="slogan-two fw-bold mt-0 georgia">
                  <em>{singleRestaurantDetails?.restaurant_tagline}</em>
                </span>
                <div className="dining-description mt-4">
                  <p>{singleRestaurantDetails?.restaurant_description}</p>
                  {/* <p className="pt-2">{paraTwo}</p> */}
                </div>
              </div>
              {/* /col-lg-6 */}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="dining-features section-padding pt-0">
          <div className="container">
            {singleRestaurantDetails?.detailData?.map((listdataview) => {
              return (
                <DiningFeatures
                  key={listdataview.restaurant_detail_id}
                  diningFeatureImg={listdataview.image_path}
                  diningAltImgTag={listdataview.img_alt_tag}
                  diningFeatureHeading={listdataview.heading}
                  diningFeaturepara={listdataview.description}
                  pointlist={listdataview.features.map((item) => {
                    return item.features;
                  })}
                />
              );
            })}
          </div>
        </div>
      </section>

      <section>
        <div className="other-restaurants section-padding pt-0">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="hotel-heading-wrap d-flex align-items-center justify-content-between">
                  <h2 className="text-uppercase georgia heading-space mb-0">
                    OTHER RESTAURANTS
                  </h2>
                  <Link
                    to={`/${slugOfDining}`}
                    className="text-dark text-decoration-none view-all"
                    title="Course Link"
                  >
                    VIEW ALL{" "}
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      className="ms-3 icon-font"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="row mt-lg-5 mt-4">
              <div className="col-12 p-0">
                <OtherRestaurants
                slugOfDining={slugOfDining}
                  restaurantItems={restRestaurants}
                  filterSlug={slug}
                  otherRestaurantsSlides={otherRestaurantsSlides}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DiningDetails;
