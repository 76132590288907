import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import resort from "../assets/images/palm-tree.svg";
import parse from "html-react-parser";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import sustainabilityManagementPdf from "../assets/documents/AKM-Sustainability-2023.pdf";
import environmentalPolicyPdf from "../assets/documents/AKM-Environmental-2023.pdf";
import { MdArrowOutward } from "react-icons/md";
import Image from "./Image"
function ExperienceAwait({
  smallHeading,
  sectionClass,
  heading,
  para,
  image,
  showLearnMore = true,
  showHeading = true,
  visitLinks = false,
  showLargeheading = false,
  showMenu = false,
  susPdf,
  envPdf,
  akiraMenuPDF,
}) {
  return (
    <>
      <section>
        <div
          className={`atmosphere-experience-await section-padding ${sectionClass}`}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <div className="heading-icon mb-xl-4 mb-2 pt-2 pb-2">
                  {/* <img
                    src={image}
                    title=""
                    alt=""
                  /> */}
                   <Image className="" src={image} alt="" />

                </div>
                {smallHeading ? (
                  <div className="small-heading">{smallHeading}</div>
                ) : (
                  ""
                )}
                {showLargeheading ? (
                  <h2 className="m-auto text-uppercase georgia heading-space mt-xl-4 mt-2 h2">
                    {heading}
                  </h2>
                ) : (
                  <h1 className="m-auto text-uppercase georgia heading-space mt-xl-4 mt-2 h2">
                    {heading}
                  </h1>
                )}
              </div>
              <div className="col-12 text-center">
                <div className="paragraph m-auto pt-3 text-center">
                  {/* {parse(para)} */}
                  <p>{para}</p>
                </div>
                {showLearnMore ? (
                  <div className="action-btn d-none mt-lg-5 mt-4 text-center">
                    <Link
                      to="/our-story"
                      className="btn theme-btn fw-medium text-white text-uppercase border-theme"
                    >
                      Our Story
                    </Link>
                  </div>
                ) : (
                  ""
                )}

                {showMenu && akiraMenuPDF ? (
                  akiraMenuPDF.endsWith(".pdf") ? (
                    <Link
                      to={akiraMenuPDF}
                      target="_blank"
                      className="mt-lg-5 mt-4 georgia w-auto btn theme-btn-outline btn-outline-success fw-medium text-text-capitalize border-theme"
                    >
                      View Menu <MdArrowOutward className="ms-2" />
                    </Link>
                  ) : (
                    <a
                      href={akiraMenuPDF}
                      target="_blank"
                      className="mt-lg-5 mt-4 georgia w-auto btn theme-btn-outline btn-outline-success fw-medium text-text-capitalize border-theme"

                    >

                      View Menu <MdArrowOutward className="ms-2" />
                    </a>
                  )
                ) : (
                  ""
                )}

                {visitLinks ? (
                  <div className="site-visit-links d-flex flex-wrap justify-content-center mt-xl-5 mt-4">
                    {susPdf ? (<Link
                      to={susPdf}
                      target="_blank"
                      className="georgia btn theme-btn-outline btn-outline-success fw-medium text-text-capitalize border-theme"
                    >
                      Sustainability Management{" "}
                      <FontAwesomeIcon icon={faArrowUp} className="ms-2" />
                    </Link>) : ("")

                    }

                    {envPdf ? (
                      <Link
                        to={envPdf}
                        target="_blank"
                        className="georgia btn theme-btn-outline btn-outline-success fw-medium text-text-capitalize border-theme ms-md-2 ms-lg-4 ms-0 mt-md-0 mt-3"
                      >
                        Environmental Policy{" "}
                        <FontAwesomeIcon icon={faArrowUp} className="ms-2" />
                      </Link>
                    ) : ("")}

                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ExperienceAwait;
