import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { useQuery } from "react-query";
import { getResortData } from "../services/dataServices";

function Modals({ show, handleClose }) {
  let currentDateTime = new Date();
  const currentDate = currentDateTime.getDate();
  const nextDate = new Date(currentDateTime.setDate(currentDate + 1));
  const [bookingStartDate, setStart] = useState(new Date());
  const [bookingEndDate, setEnd] = useState(nextDate);
  const [selectedItemResort, setSelectedItem] = useState();
  const allResortdata = useQuery("allresortdata", getResortData);

  /* when getting error from resort API than this static data use in else condition */
  const resortData =
    allResortdata?.data?.data == null
      ? [
          {
            brand_resort_id: 1,
            resort_code: "95216",
            resort_name: "Atmosphere - Kanifushi",
          },
          {
            brand_resort_id: 2,
            resort_code: "110184",
            resort_name: "By Atmosphere - Varu",
          },
        ]
      : allResortdata.data.data;

  // useEffect(() => {
  //   // Function to fetch data from the API
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(
  //         "http://172.16.1.113:8000/api/v1/other/get-ahr-resorts-list"
  //       ); // Replace with your API endpoint
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       const result = await response.json();
  //       setData(result.data);
  //     } catch (error) {
  //       setError(error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   // Call the fetchData function
  //   fetchData();
  // }, []); //

  const handleSelectChange = (event) => {
    const selectedItemCode = event.target.value;
    const selectedItemResort = resortData.find(
      (item) => item.resort_code === selectedItemCode
    );
    setSelectedItem(selectedItemResort);
    setFormData({
      ...formData,
      resort: selectedItemCode,
    });
  };

  const [formData, setFormData] = useState({
    resort: 95216,
    discountCode: "",
    iataCode: "",
    adultCount: "2",
    childrenCount: "0",
    roomCount: "1",
  });

  ///adult count
  const adultIncrementBtn = (event) => {
    event.preventDefault();
    setFormData({ ...formData, adultCount: +formData.adultCount + 1 });
  };

  const adultDecrementBtn = (e) => {
    e.preventDefault();
    if (formData.adultCount > 1) {
      setFormData({ ...formData, adultCount: +formData.adultCount - 1 });
    }
  };
  /* End adult count */

  /* Start children count*/
  const childrenIncrement = (event) => {
    event.preventDefault();
    setFormData({ ...formData, childrenCount: +formData.childrenCount + 1 });
  };

  const childrenDecrement = (event) => {
    event.preventDefault();
    if (formData.childrenCount > 0) {
      setFormData({ ...formData, childrenCount: +formData.childrenCount - 1 });
    }
  };
  /* End children count*/

  /* Start Room count*/

  const roomIncrementBtn = (event) => {
    event.preventDefault();
    setFormData({ ...formData, roomCount: +formData.roomCount + 1 });
  };

  const roomDecrementBtn = (event) => {
    event.preventDefault();
    if (formData.roomCount > 1) {
      setFormData({ ...formData, roomCount: +formData.roomCount - 1 });
    }
  };
  /* End Room count*/

  const handleDiscountCodeChange = (event) => {
    setFormData({
      ...formData,
      discountCode: event.target.value,
    });
  };
  const handleIataCodeChange = (event) => {
    setFormData({
      ...formData,
      iataCode: event.target.value,
    });
  };

  // Format date to MM-DD-YYYY
  const formatDate = (date) => format(date, "MM/dd/yyyy");

  /*Submit form Action Start here */
  const handleSubmit = (event) => {
    event.preventDefault();

    // Access formData for submission or further processing

    const queryParams = new URLSearchParams();
    // queryParams.set('resortId',formData.resort)
    queryParams.set("adults", formData.adultCount);
    queryParams.set("children", formData.childrenCount);
    queryParams.set("datein", formatDate(bookingStartDate));
    queryParams.set("dateOut", formatDate(bookingEndDate));
    queryParams.set("rooms", formData.roomCount);
    queryParams.set("discount", formData.discountCode);
    queryParams.set("iata", formData.iataCode);
    
    const newTab = window.open(
      `https://reservations.travelclick.com/${
        formData.resort
      }?${queryParams.toString()}`,
      "_blank"
    );
    // Focus on the new tab (optional)
    if (newTab) {
      newTab.focus();
    }

    // if (formData.resort > 0) {
    //   const newTab = window.open(
    //     `https://reservations.travelclick.com/${formData.resort
    //     }?${queryParams.toString()}`,
    //     "_blank"
    //   );
    //   // Focus on the new tab (optional)
    //   if (newTab) {
    //     newTab.focus();
    //   }
    // } else {
    //   const newTab = window.open(
    //     `https://search.travelclick.com/ahr/`,
    //     "_blank"
    //   );
    //   // Focus on the new tab (optional)
    //   if (newTab) {
    //     newTab.focus();
    //   }
    // }
  };
  /**End Submit form here **/

  // if (loading) {
  //   return <p>Loading...</p>;
  // }

  // if (error) {
  //   return <p>Error: {error.message}</p>;
  // }

  return (
    <Modal className="book-now-modal" show={show} onHide={handleClose}>
      <Modal.Header className="border-0" closeButton>
        <Modal.Title className="h2 text-white georgia text-uppercase">
          BOOK YOUR STAY
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="mb-4">
            <Form.Label for="Resort" className="text-uppercase text-white">
              Check in <span className="mx-1">-</span> Check out
            </Form.Label>
            <DateRangePicker
              initialSettings={{
                locale: {
                  format: "DD/MM/YYYY",
                },
                startDate: bookingStartDate,
                endDate: bookingEndDate,
                minDate: new Date(),
                autoApply: true,
              }}
              onApply={(event, picker) => {
                setStart(new Date(picker.startDate));
                setEnd(new Date(picker.endDate));
              }}
            >
              <input
                type="text"
                className="form-control shadow-none text-start "
              />
            </DateRangePicker>
          </div>
          <div className="row mb-4">
            <div className="col-md-4">
              <Form.Label className="text-uppercase text-white">
                Rooms
              </Form.Label>
              <div className="input-number position-relative">
                <button
                  className="input-number-increment"
                  onClick={roomDecrementBtn}
                >
                  <FontAwesomeIcon icon={faAngleLeft} />
                </button>
                <input
                  type="number"
                  value={formData.roomCount}
                  id="inuput-number"
                  className="form-control shadow-none text-center input-arrow"
                />
                <button
                  className="input-number-decrement"
                  onClick={roomIncrementBtn}
                >
                  <FontAwesomeIcon icon={faAngleRight} />
                </button>
              </div>
            </div>
            <div className="col-md-4">
              <Form.Label className="text-uppercase text-white">
                Adult
              </Form.Label>
              <div className="input-number position-relative">
                <button
                  className="input-number-increment"
                  onClick={adultDecrementBtn}
                >
                  <FontAwesomeIcon icon={faAngleLeft} />
                </button>
                <Form.Control
                  type="number"
                  id="inuput-number"
                  className="form-control shadow-none text-center"
                  value={formData.adultCount}
                />
                <button
                  className="input-number-decrement"
                  onClick={adultIncrementBtn}
                >
                  <FontAwesomeIcon icon={faAngleRight} />
                </button>
              </div>
            </div>
            <div className="col-md-4">
              <Form.Label className="text-uppercase text-white">
                Children
              </Form.Label>
              <div className="input-number position-relative">
                <button
                  className="input-number-increment"
                  onClick={childrenDecrement}
                >
                  <FontAwesomeIcon icon={faAngleLeft} />
                </button>
                <Form.Control
                  type="number"
                  id="inuput-number"
                  className="form-control shadow-none text-center"
                  value={formData.childrenCount}
                />
                <button
                  className="input-number-decrement"
                  onClick={childrenIncrement}
                >
                  <FontAwesomeIcon icon={faAngleRight} />
                </button>
              </div>
            </div>
          </div>
          {/* /row */}
          <div className="row mb-4">
            <div className="col-md-6">
              <Form.Label className="text-uppercase text-white">
                Discount Code
              </Form.Label>
              <Form.Control
                value={formData.discountCode}
                type="text"
                className="form-control shadow-none"
                placeholder="Enter code here"
                onChange={handleDiscountCodeChange}
              />
            </div>
            <div className="col-md-6">
              <Form.Label className="text-uppercase text-white">
                IATA Code
              </Form.Label>
              <Form.Control
                value={formData.iataCode}
                type="text"
                className="form-control shadow-none"
                placeholder="Enter code here"
                onChange={handleIataCodeChange}
              />
            </div>
          </div>
          {/* /row */}
          <div className="pt-3">
            <div className="form-submit">
              <input
                type="submit"
                className="btn primary theme-btn fw-medium text-white text-uppercase border-theme"
                value={"CHECK AVAILABILITY"}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default Modals;
